<template>
  <form>
    <div class="vx-row">
      <div class="vx-col mb-5 w-full">
        <label class="text-sm"> Product </label>
        <v-select label="name" class="" v-model="data.product_id" :reduce="item => item.id" placeholder="--select product--" name="product"
           :closeOnSelect="true" :options="products" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
      </div>
      <div class="vx-col mb-5 w-full">
        <label class="text-sm"> Write Review </label>
        <vs-textarea label="Write Review" v-validate="{ required: true }" v-model="data.content" rows="3" name="content" />
        <span class="text-danger text-sm" v-show="errors.has('content')">{{ errors.first('content') }}</span>
        <div class="mt-3 flex justify-between items-center">
          <div>
            <star-rating :rtl="$vs.rtl" :rating="data.rating" @rating-selected="setRating" ></star-rating>
          </div>
        </div>
      </div>

      <div v-if="!data.is_real" class="vx-col mb-5 w-full">
        <vs-input label="Client Name *" placeholder="Reviewer's Name" name="reviewer_name" v-model="data.reviewer_name" class="w-full" />
      </div>

      <div v-if="!data.is_real" class="vx-col mb-5 w-full">
        <label class="text-sm"> Country </label>
        <v-select label="name"  name="country"
          v-model="data.country_id" :reduce="item => item.id" placeholder="--select country--" :closeOnSelect="true" :options="countries" :dir="$vs.rtl ? 'rtl' : 'ltr'" />
      </div>

      <div v-if="!data.is_real" class="vx-col mb-5 w-full">
        <label class="text-sm">Review Date</label>
        <flat-pickr v-model="data.reviewed_at" :config="{ dateFormat: 'd F Y' }" class="w-full" placeholder="Publish Date" name="reviewed_at" />
      </div>

      <div  v-if="!data.is_real" class="vx-col mb-5 w-full">
        <vue-dropzone id="dropzone" ref="catBannerfileUploadDrop" 
          :maxFiles="1" :use-custom-slot="true" :maxFilesize="5" acceptedFiles="image/*,.webp">
          <div class="needsclick m-0">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-upload-cloud"><polyline points="16 16 12 12 8 16"></polyline><line x1="12" y1="12" x2="12" y2="21"></line><path d="M20.39 18.39A5 5 0 0 0 18 9h-1.26A8 8 0 1 0 3 16.3"></path><polyline points="16 16 12 12 8 16"></polyline></svg>
            <h4>Upload Reviewer Image</h4>
            <span class="text-primary font-13">
              Dimension should be <code>100px</code> x <code>100px</code><br> Maximum Size <code> 500kb</code>
            </span>
          </div>
        </vue-dropzone>
      </div>

      <div class="vx-col mb-5 w-full">
        <label class="text-sm">Write Response (if any)</label>
        <vs-textarea label="Response" v-model="data.response" rows="2" name="response" />
      </div>

      <div v-if="!data.is_real" class="vx-col mb-5 w-full">
        <div class="vx-col w-full">
          <label class="text-sm">Response Date</label>
          <flat-pickr v-model="data.responded_at" :config="{ dateFormat: 'd F Y' }" class="w-full" placeholder="Publish Date" />
        </div>
      </div>
    </div>
    <vs-button class="ml-auto mt-2" @click="editMode ? updateItem(): createItem()" >{{editMode ? 'Update':'Add Review'}}</vs-button>
  </form>
</template>

<script>
import StarRating from 'vue-star-rating'
import flatPickr from 'vue-flatpickr-component'
import VueDropzone from "@/components/VueDropzone.vue";

export default {
  components:{
    flatPickr,
    StarRating,
    VueDropzone
  },
  props:{
    editItem:{
      type: Object,
      default: null
    },
    editMode:{
      type: Boolean,
      default: false
    }
  },
  data(){
    return{
      data: {
        rating: 0,
        content: "",
        reviewer_name: "",
        reviewed_at: null,
        category: "REVIEW",
        is_real: false,
        response:"",
        responded_at:null,
        product_id: "",
        country_id:"",
      },
    }
  },
  computed: {
    countries(){
      return this.$store.state.countries
    },
    products(){
      return this.$store.state.productList.products
    }
  },
  methods:{
    setRating: function(rating) {
      this.data.rating = rating;
    },
    createItem(){
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form not valid"); return}
        let formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        if(this.collectFiles().length){
          formData.append('reviewer_image_file', this.collectFiles()[0])
        }
        this.$vs.loading({color: "#444", type:'sound'})
        this.$http.post('/reviews/create', formData)
        .then((response) => {
          this.$vs.loading.close()
          if(response.data.success){
            this.$store.commit("reviewList/ADD_REVIEW", response.data.data.review)
            this.resetAll()
          }
        })
      })
    },
    updateItem(){
      this.$validator.validateAll().then(result => {
        if (!result) { this.alertError("Form not valid"); return}
        let formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        if(this.collectFiles().length){
          formData.append('reviewer_image_file', this.collectFiles()[0])
        }
        this.$vs.loading({color: "#444", type:'sound'})
        this.$http.post(`/reviews/${this.editItem.id}/update`, formData)
        .then((response) => {
          this.$vs.loading.close()
          if(response.data.success){
            this.$store.commit("reviewList/UPDATE_REVIEW", response.data.data.review)
            this.resetAll()
            this.$emit("closeform")
          }
        })
      })
    },
    collectFiles(){
      var arrafile = [];
      if(this.$refs.reviewImageDrop){
        arrafile = [...this.$refs.reviewImageDrop.getAcceptedFiles()];
      }
      return arrafile
    },
    resetAll() {
      this.$validator.reset()
      this.data = {
        rating: 0,
        content: "",
        reviewer_name: "",
        reviewed_at: null,
        category: "REVIEW",
        is_real: false,
        response:"",
        responded_at: null,
        product_id: "",
        country_id:"",
      }
      if(this.$refs.reviewImageDrop){
        this.$refs.reviewImageDrop.removeAllFiles();
      }
    },
    patchUpdatables(){
      let allowed = Object.keys(this.data);
      return Object.keys(this.editItem)
          .filter(key => allowed.includes(key))
          .reduce((obj, key) => {
              obj[key] = this.editItem[key];
              return obj;
          }, {});
    }
  },
  mounted(){
    if(this.editMode){
      this.data = JSON.parse(JSON.stringify(this.patchUpdatables()))
      this.data.reviewed_at = this.data.reviewed_at ? new Date(this.data.reviewed_at) : null;
      this.data.responded_at = this.data.responded_at ? new Date(this.data.responded_at) : null;
    }
  }
}
</script>
